import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { PageContent } from "../features/brand-pages"

const query = graphql`
  {
    allStrapiBrandPage(filter: { slug: { eq: "slowbread" } }) {
      nodes {
        id
        slug
        strapiId
        underContents
        pageMainVisual {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        additionalPageMainVisuals {
          formats {
            large {
              id
              childImageSharp {
                fixed(quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        mainLogo {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }

    allStrapiProducts(
      filter: { brand: { slug: { eq: "slowbread" } }, isDisplay: { eq: true } }
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        id
        name
        excerpt
        catchPhrase
        slug
        mainImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default function SlowbreadPage() {
  const {
    allStrapiBrandPage: pageData,
    allStrapiProducts: products,
  } = useStaticQuery(query)

  const sources = [
    pageData.nodes[0].additionalPageMainVisuals[0].formats.large.childImageSharp
      .fluid,
    {
      ...pageData.nodes[0].pageMainVisual.childImageSharp.fluid,
      media: `(min-width: 600px)`,
    },
  ]
  return (
    <Layout>
      <SEO>
        <title>SLOW&nbsp;BREAD｜フランソア</title>
        <meta
          name="description"
          content="おいしい。そしてヘルシー。フランソアのパン商品ラインナップ。"
        />
      </SEO>

      <PageContent
        pageData={pageData.nodes[0]}
        products={products.nodes}
        slug={pageData.nodes[0].slug}
        backgroundImageSrcset={sources}
      />
    </Layout>
  )
}
